import { StaticImage } from "gatsby-plugin-image";
import React from "react";

export default function Toastr({ text, variant, close = () => void 0 as any }) {
  const className =
    variant === "success"
      ? "bg-[#C5FF98] text-grey-600"
      : variant === "error" || variant === "danger"
        ? "bg-[#FF9E9E] text-grey-600"
        : "bg-grey-600 text-white";

  const imgClassName =
    variant === "error" || variant === "danger" || variant === "success"
      ? "invert"
      : "";

  return (
    <div className={`fixed z-[10000000000] left-0 bottom-0 lg:left-8 lg:bottom-6 animate-in animate-out animate-duration-100 gap-4 animate-fade-up rounded-t-xl lg:!rounded-lg flex items-center justify-between p-4 w-[450px] max-w-full font-semibold ${className}`}>
      <div className='flex items-center gap-4 flex-1 min-w-0'>
        {variant === "success" 
          ? <StaticImage className='shrink-0' alt="success icon" src="../../images/icons/check_circle.png" width={20} height={20} />
          : variant === "error" || variant === "danger" 
            ? <StaticImage className='shrink-0' alt="error icon" src="../../images/icons/x_circle.png" width={20} height={20} />
            : <></>
        }
        <span className='text-body3 line-clamp-3 flex-1 break-words min-w-0'>{text}</span>
      </div>
      <span onClick={close} className="shrink-0">
        <StaticImage
          alt="x"
          src="../../images/icons/cancel.png"
          width={14}
          height={14}
          className={`cursor-pointer ${imgClassName}`}
        />
      </span>
    </div>
  );
}
