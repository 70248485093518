import { propTypes } from "gatsby-plugin-image/dist/src/components/gatsby-image.server";
import React from "react";

export default function Card({ children, className = "", ...props }) {
  return (
    <div
      className={`card p-4 lg:p-6 rounded-2xl lg:rounded-lg bg-[#373539] shadow-[0px,4px,4px,0px,#00000040] ${className}`}
      {...props}
    >
      {children}
    </div>
  );
}
