import React, { createContext, useContext, useEffect } from "react";
import { useMutation } from "@apollo/client";
import { TRACK_EVENT } from "../config/queries";
import { ENVIRONMENT, MIXPANEL_TOKEN, NULL_ADDRESS } from "../config/constants";
import { ChainContext } from "./ChainContext";
import { NFTContext } from "./NFTContext";
import { FormContext } from "./FormContext";
import { ReferralsContext } from "./ReferralsContext";
import useTokenPrices from "../hooks/useTokenPrices";
import mixpanel from "mixpanel-browser";

export interface TrackEventInput {
  eventName: string;
  eventData: string; // JSON stringified properties
}

const LogContext = createContext({
  track: (event: string, properties: any) => {},
});

const serializeBigInt = (obj: any): any => {
  return Object.keys(obj).reduce((acc, key) => {
    const value = obj[key];
    if (typeof value === 'bigint') {
      acc[key] = value.toString();
    } else if (typeof value === 'object' && value !== null) {
      acc[key] = serializeBigInt(value);
    } else {
      acc[key] = value;
    }
    return acc;
  }, {} as any);
};

export const LogProvider = ({ children }) => {
  const { form } = useContext(FormContext);
  const { connectedAccount } = useContext(ChainContext);
  const { referrals } = useContext(ReferralsContext);
  const { nft } = useContext(NFTContext);
  const { getTokenPrices, data: tokenPrices } = useTokenPrices();
  const [trackEvent] = useMutation(TRACK_EVENT);

  const nativeTokenPrice = tokenPrices?.tokenPrices?.ratio || null;

  const track = async (event: string, properties: any) => {

    const originalProperties = properties;

    // Convert any object properties into multiple properties
    properties = Object.keys(properties).reduce((acc, key) => {
      const value = properties[key];
      if (typeof value === 'object' && value !== null) {
        const flattenObject = (obj: any, prefix = '') => {
          return Object.keys(obj).reduce((acc2, k) => {
            const pre = prefix.length ? prefix + '.' : '';
            if (typeof obj[k] === 'object' && obj[k] !== null) {
              // Skip if array
              if (Array.isArray(obj[k])) {
                return acc2;
              }
              Object.assign(acc2, flattenObject(obj[k], pre + k));
            } else {
              acc2[pre + k] = obj[k];
            }
            return acc2;
          }, {});
        };
        
        // Skip if array
        if (!Array.isArray(value)) {
          Object.assign(acc, flattenObject(value, key));
        }
      } else {
        acc[key] = value;
      }
      return acc;
    }, {} as any);

    properties = {
      ...properties,
      // General data
      environment: ENVIRONMENT,
      nativeTokenPrice,
      currentUrl: window?.location?.href,
      timestamp: Date.now(),
      address: connectedAccount,
      // NFT data
      nftPoints: nft ? (nft as any)?.attributes?.find((e) => e?.trait_type === "Points")?.value ?? null : null,
      nftType: nft ? (nft as any)?.attributes?.find((e) => e?.trait_type === "Type")?.value ?? null : null,
      // Referrals data
      referralsAmount: referrals?.length || null,
      referralsAddresses: referrals?.length ? referrals?.map((e) => e?.address)?.join(",") : null,
      // Browser/Device data
      browser: navigator?.userAgent?.match(/chrome|firefox|safari|edge|opera/i)?.[0] || "unknown",
      browserVersion: navigator?.userAgent?.match(/version\/[\d.]+|chrome\/[\d.]+|firefox\/[\d.]+/i)?.[0] || "unknown",
      operatingSystem: (navigator as any)?.userAgentData?.platform || 
        (/Windows/i.test(navigator?.userAgent) ? "Windows" :
         /Mac/i.test(navigator?.userAgent) ? "Mac" :
         /Linux/i.test(navigator?.userAgent) ? "Linux" :
         /Android/i.test(navigator?.userAgent) ? "Android" :
         /iOS/i.test(navigator?.userAgent) ? "iOS" : "unknown"),
      device: /mobile|android|iphone|ipad/i.test(navigator?.userAgent) ? "mobile" : "desktop",
      screenWidth: window?.innerWidth,
      screenHeight: window?.innerHeight,
    }

    properties = {
      'address': properties?.['address'],
      'aggregatorId': properties?.['aggregatorId'],
      'blockchainTx.accessList': properties?.['blockchainTx.accessList'],
      'blockchainTx.blobVersionedHashes': properties?.['blockchainTx.blobVersionedHashes'],
      'blockchainTx.blockHash': properties?.['blockchainTx.blockHash'],
      'blockchainTx.blockNumber': properties?.['blockchainTx.blockNumber'],
      'blockchainTx.chainId': properties?.['blockchainTx.chainId'],
      'blockchainTx.data': properties?.['blockchainTx.data'],
      'blockchainTx.from': properties?.['blockchainTx.from'],
      'blockchainTx.gasLimit': properties?.['blockchainTx.gasLimit'],
      'blockchainTx.gasPrice': properties?.['blockchainTx.gasPrice'],
      'blockchainTx.hash': properties?.['blockchainTx.hash'],
      'blockchainTx.index': properties?.['blockchainTx.index'],
      'blockchainTx.maxFeePerBlobGas': properties?.['blockchainTx.maxFeePerBlobGas'],
      'blockchainTx.maxFeePerGas': properties?.['blockchainTx.maxFeePerGas'],
      'blockchainTx.maxPriorityFeePerGas': properties?.['blockchainTx.maxPriorityFeePerGas'],
      'blockchainTx.nonce': properties?.['blockchainTx.nonce'],
      'blockchainTx.to': properties?.['blockchainTx.to'],
      'blockchainTx.type': properties?.['blockchainTx.type'],
      'blockchainTx.value': properties?.['blockchainTx.value'],
      'browser': properties?.['browser'],
      'browserVersion': properties?.['browserVersion'],
      'chainIdFrom': properties?.['chainIdFrom'],
      'chainIdTo': properties?.['chainIdTo'],
      'currentUrl': properties?.['currentUrl'],
      'device': properties?.['device'],
      'environment': properties?.['environment'],
      'error': properties?.['error'] || properties?.['error.shortMessage'],
      'gasSpentInNativeToken': properties?.['gasSpentInNativeToken'],
      'isSuccess': properties?.['isSuccess'],
      'nativeTokenPrice': properties?.['nativeTokenPrice'],
      'nftPoints': properties?.['nftPoints'],
      'nftType': properties?.['nftType'],
      'operatingSystem': properties?.['operatingSystem'],
      'platformFeePaid': properties?.['platformFeePaid'],
      'quoteSwapRequest.amountFrom': properties?.['quoteSwapRequest.amountFrom'],
      'quoteSwapRequest.amountTo': properties?.['quoteSwapRequest.amountTo'],
      'quoteSwapRequest.chainIdFrom': properties?.['quoteSwapRequest.chainIdFrom'],
      'quoteSwapRequest.chainIdTo': properties?.['quoteSwapRequest.chainIdTo'],
      'quoteSwapRequest.crossChain': properties?.['quoteSwapRequest.crossChain'],
      'quoteSwapRequest.crossChainSelectedRoute': properties?.['quoteSwapRequest.crossChainSelectedRoute'],
      'quoteSwapRequest.gasPrice': properties?.['quoteSwapRequest.gasPrice'],
      'quoteSwapRequest.gasPriceId': properties?.['quoteSwapRequest.gasPriceId'],
      'quoteSwapRequest.isLimitPriceReversed': properties?.['quoteSwapRequest.isLimitPriceReversed'],
      'quoteSwapRequest.limitExpiryTimeMiliseconds': properties?.['quoteSwapRequest.limitExpiryTimeMiliseconds'],
      'quoteSwapRequest.limitPrice': properties?.['quoteSwapRequest.limitPrice'],
      'quoteSwapRequest.quoteCrossChainData': properties?.['quoteSwapRequest.quoteCrossChainData'],
      'quoteSwapRequest.quoteData.getQuote.__typename': properties?.['quoteSwapRequest.quoteData.getQuote.__typename'],
      'quoteSwapRequest.quoteData.getQuote.aggregatorId': properties?.['quoteSwapRequest.quoteData.getQuote.aggregatorId'],
      'quoteSwapRequest.quoteData.getQuote.estimatedGas': properties?.['quoteSwapRequest.quoteData.getQuote.estimatedGas'],
      'quoteSwapRequest.quoteData.getQuote.outAmount': properties?.['quoteSwapRequest.quoteData.getQuote.outAmount'],
      'quoteSwapRequest.slippage': properties?.['quoteSwapRequest.slippage'],
      'quoteSwapRequest.tokenAddressFrom': properties?.['quoteSwapRequest.tokenAddressFrom'],
      'quoteSwapRequest.tokenAddressTo': properties?.['quoteSwapRequest.tokenAddressTo'],
      'quoteSwapRequest.type': properties?.['quoteSwapRequest.type'],
      'quoteSwapResponse.__typename': properties?.['quoteSwapResponse.__typename'],
      'quoteSwapResponse.data': properties?.['quoteSwapResponse.data'],
      'quoteSwapResponse.estimatedGas': properties?.['quoteSwapResponse.estimatedGas'],
      'quoteSwapResponse.minOutAmount': properties?.['quoteSwapResponse.minOutAmount'],
      'quoteSwapResponse.outAmount': properties?.['quoteSwapResponse.outAmount'],
      'quoteSwapResponse.value': properties?.['quoteSwapResponse.value'],
      'referralsAddresses': properties?.['referralsAddresses'],
      'referralsAmount': properties?.['referralsAmount'],
      'screenHeight': properties?.['screenHeight'],
      'screenWidth': properties?.['screenWidth'],
      'timestamp': properties?.['timestamp'],
      'tokenAddressFrom': properties?.['tokenAddressFrom'],
      'tokenAddressTo': properties?.['tokenAddressTo'],
      'tokenSymbolFrom': properties?.['tokenSymbolFrom'],
      'tokenSymbolTo': properties?.['tokenSymbolTo'],
      'quoteSwapRequest.quoteCrossChainData.getCrossChainQuote.__typename': properties?.['quoteSwapRequest.quoteCrossChainData.getCrossChainQuote.__typename'],
      'quoteSwapRequest.quoteCrossChainData.getCrossChainQuote.code': properties?.['quoteSwapRequest.quoteCrossChainData.getCrossChainQuote.code'],
      'quoteSwapRequest.quoteCrossChainData.getCrossChainQuote.crossChainQuote.__typename': properties?.['quoteSwapRequest.quoteCrossChainData.getCrossChainQuote.crossChainQuote.__typename'],
      'quoteSwapRequest.quoteCrossChainData.getCrossChainQuote.crossChainQuote.aggregatorId': properties?.['quoteSwapRequest.quoteCrossChainData.getCrossChainQuote.crossChainQuote.aggregatorId'],
      'quoteSwapRequest.quoteCrossChainData.getCrossChainQuote.crossChainQuote.bridgeInfo.__typename': properties?.['quoteSwapRequest.quoteCrossChainData.getCrossChainQuote.crossChainQuote.bridgeInfo.__typename'],
      'quoteSwapRequest.quoteCrossChainData.getCrossChainQuote.crossChainQuote.bridgeInfo.displayName': properties?.['quoteSwapRequest.quoteCrossChainData.getCrossChainQuote.crossChainQuote.bridgeInfo.displayName'],
      'quoteSwapRequest.quoteCrossChainData.getCrossChainQuote.crossChainQuote.bridgeInfo.icon': properties?.['quoteSwapRequest.quoteCrossChainData.getCrossChainQuote.crossChainQuote.bridgeInfo.icon'],
      'quoteSwapRequest.quoteCrossChainData.getCrossChainQuote.crossChainQuote.estimateCostInUSD': properties?.['quoteSwapRequest.quoteCrossChainData.getCrossChainQuote.crossChainQuote.estimateCostInUSD'],
      'quoteSwapRequest.quoteCrossChainData.getCrossChainQuote.crossChainQuote.estimatedGas': properties?.['quoteSwapRequest.quoteCrossChainData.getCrossChainQuote.crossChainQuote.estimatedGas'],
      'quoteSwapRequest.quoteCrossChainData.getCrossChainQuote.crossChainQuote.estimatedTime': properties?.['quoteSwapRequest.quoteCrossChainData.getCrossChainQuote.crossChainQuote.estimatedTime'],
      'quoteSwapRequest.quoteCrossChainData.getCrossChainQuote.crossChainQuote.fromTokenAmount': properties?.['quoteSwapRequest.quoteCrossChainData.getCrossChainQuote.crossChainQuote.fromTokenAmount'],
      'quoteSwapRequest.quoteCrossChainData.getCrossChainQuote.crossChainQuote.middlewareRoute.__typename': properties?.['quoteSwapRequest.quoteCrossChainData.getCrossChainQuote.crossChainQuote.middlewareRoute.__typename'],
      'quoteSwapRequest.quoteCrossChainData.getCrossChainQuote.crossChainQuote.minimumReceived': properties?.['quoteSwapRequest.quoteCrossChainData.getCrossChainQuote.crossChainQuote.minimumReceived'],
      'quoteSwapRequest.quoteCrossChainData.getCrossChainQuote.crossChainQuote.toTokenAmount': properties?.['quoteSwapRequest.quoteCrossChainData.getCrossChainQuote.crossChainQuote.toTokenAmount'],
      'quoteSwapRequest.quoteCrossChainData.getCrossChainQuote.message': properties?.['quoteSwapRequest.quoteCrossChainData.getCrossChainQuote.message'],
      'quoteSwapRequest.quoteCrossChainData.getCrossChainQuote.success': properties?.['quoteSwapRequest.quoteCrossChainData.getCrossChainQuote.success'],
      'quoteSwapRequest.quoteData': properties?.['quoteSwapRequest.quoteData'],
      'quoteSwapResponse.code': properties?.['quoteSwapResponse.code'],
      'quoteSwapResponse.crossChainSwap.__typename': properties?.['quoteSwapResponse.crossChainSwap.__typename'],
      'quoteSwapResponse.crossChainSwap.calldata': properties?.['quoteSwapResponse.crossChainSwap.calldata'],
      'quoteSwapResponse.crossChainSwap.value': properties?.['quoteSwapResponse.crossChainSwap.value'],
      'quoteSwapResponse.message': properties?.['quoteSwapResponse.message'],
      'quoteSwapResponse.success': properties?.['quoteSwapResponse.success'],
      'error.code': properties?.['error.code'],
      'error.shortMessage': properties?.['error.shortMessage'],
      'error.transaction.data': properties?.['error.transaction.data'],
      'error.transaction.from': properties?.['error.transaction.from'],
      'error.transaction.to': properties?.['error.transaction.to'],
    }

    const eventInput: TrackEventInput = {
      eventName: event?.toLowerCase(),
      eventData: JSON.stringify(serializeBigInt(properties)),
    };


    // Track event in Mixpanel
    try {
      mixpanel.track(event, properties);
    } catch (e) {
      console.error(e);
    }

    // Track event in database
    try {
      await trackEvent({
        variables: {
          event: eventInput
        }
      });
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    mixpanel.init(MIXPANEL_TOKEN, {
      debug: false,
      track_pageview: false,
      persistence: "localStorage",
    });
    if (connectedAccount) {
      mixpanel.identify(connectedAccount);
    }
  }, []);

  useEffect(() => {
    if (form?.chainIdFrom) {
      getTokenPrices(form?.chainIdFrom, NULL_ADDRESS);
    }
  }, [form?.chainIdFrom]);

  return (
    <LogContext.Provider value={{ track }}>
      {children}
    </LogContext.Provider>
  );
};

export const useLog = () => useContext(LogContext);
