import { useState, useEffect, useRef, useCallback } from 'react';
import { ethers } from 'ethers';
import { getProvider } from '../utils/getProvider';

interface BaseCall {
  chainId: number;
  address: string;
}

interface StandardCall extends BaseCall {
  abi: ethers.Interface;
  method: string;
  args: any[];
}

interface CalldataCall extends BaseCall {
  calldata: string;
}

type Call = StandardCall | CalldataCall;

interface CacheItem {
  result: any;
  timestamp: number;
}

interface Cache {
  [key: string]: CacheItem;
}

interface ResultItem {
  success: boolean;
  value?: any;
  error?: string;
}

const globalCache: Cache = {};
const globalLastFetchTime: { [key: string]: number } = {};

export default function useChainCalls(calls: Call[] | null, useCache: boolean = true, pollInterval: number = 20000) {
  const [data, setData] = useState<ResultItem[] | undefined>(undefined);
  const [error, setError] = useState<Error | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const getCacheKey = useCallback((call: Call): string => 
    JSON.stringify({ 
      address: call.address, 
      chainId: call.chainId,
      ...(isStandardCall(call) 
        ? { method: call.method, args: call.args }
        : { calldata: call.calldata })
    }), []);

  const isStandardCall = (call: Call): call is StandardCall => {
    return 'method' in call && 'args' in call && 'abi' in call;
  };

  const executeCall = useCallback(async (call: Call, provider: ethers.Provider): Promise<any> => {
    if (isStandardCall(call)) {
      const contract = new ethers.Contract(call.address, call.abi, provider);
      return await contract[call.method](...call.args);
    } else {
      return await provider.call({
        to: call.address,
        data: call.calldata,
      });
    }
  }, []);

  const fetchData = async (forceUpdate: boolean = false) => {
    if (!calls || calls.length === 0) {
      setData(undefined);
      return;
    }

    const now = Date.now();
    const results: ResultItem[] = [];

    for (const call of calls) {
      const provider = getProvider(call.chainId);
      const cacheKey = getCacheKey(call);

      if (!forceUpdate && !!useCache && globalCache[cacheKey] && now - globalCache[cacheKey].timestamp < pollInterval) {
        results.push({ success: true, value: globalCache[cacheKey].result });
      } else {
        try {
          const result = await executeCall(call, provider);
          globalCache[cacheKey] = { result, timestamp: now };
          results.push({ success: true, value: result });
        } catch (error) {
          results.push({ success: false, error: error instanceof Error ? error.message : 'An unknown error occurred' });
        }
      }
    }

    setData(results);
    globalLastFetchTime[JSON.stringify(calls)] = now;
    setLoading(false);
  }

  useEffect(() => {
    setData(undefined);
    globalLastFetchTime[JSON.stringify(calls)] = 0;
  }, [JSON.stringify(calls)]);

  useEffect(() => {
    fetchData();
    const intervalId = setInterval(() => fetchData(true), pollInterval);
    return () => clearInterval(intervalId);
  }, [JSON.stringify(calls), pollInterval]);

  return data;
}