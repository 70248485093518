import React, { useContext } from "react";
import { ModalContext } from "../../contexts/ModalContext";
import { StaticImage } from "gatsby-plugin-image";

export default function Modal({
  title = "",
  children,
  overrideCloseModal = undefined as any,
  className = "",
  contentClassName = "",
  bodyClassName = "",
  ...rest
}) {
  const { closeModal } = useContext(ModalContext);

  return (
    <div
      className={`z-[1000000] !fixed left-0 top-0 w-screen h-screen flex items-center justify-center p-4 ${className}`}
      {...rest}
    >
      <div
        className="absolute left-0 top-0 w-full h-full bg-black/60 backdrop-blur-[2.5px]"
        onClick={overrideCloseModal || closeModal}
      />
      <div
        onClick={(e) => e.preventDefault}
        className={`max-w-[460px] w-full max-h-full h-auto lg:max-h-auto flex flex-col rounded-lg overflow-hidden z-[2] animate-fade-up animate-duration-300 animate-once ${contentClassName}`}
      >
        {!!title && (
          <div className="relative shrink-0 bg-grey-700 h-[45px] items-center text-white flex justify-between rounded-t-lg">
            <div className="text-center flex-1">{title}</div>
            <div
              className="cursor-pointer absolute right-6 top-1/2 -translate-y-1/2"
              onClick={overrideCloseModal || closeModal}
            >
              <StaticImage
                src="../../images/icons/cancel.png"
                alt=""
                width={15}
                height={15}
              />
            </div>
          </div>
        )}
        <div
          className={`flex-1 overflow-y-auto p-4 bg-grey-600 rounded-b-lg ${bodyClassName}`}
        >
          {children}
        </div>
      </div>
    </div>
  );
}
