import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useContext, useEffect } from "react";
import {
  ASSOCIATE_REFERRAL,
  CREATE_NICKNAME,
  GET_ADDRESS_BY_NICKNAME,
  GET_NICKNAME_BY_ADDRESS,
  GET_REFERRALS_BY_ADDRESS,
} from "../config/queries";
import { ChainContext } from "../contexts/ChainContext";
import { ToastrContext } from "../contexts/ToastrContext";
import { PUBLIC_URL } from "../config/constants";

export default function useReferrals() {
  const { connectedAccount } = useContext(ChainContext);
  const { notify } = useContext(ToastrContext);
  const [_associateReferral] = useMutation(ASSOCIATE_REFERRAL);
  const [_createNickname, { loading: isCreatingNickname }] =
    useMutation(CREATE_NICKNAME);
  const [_getAddressByNickname, { loading: isLoadingNickname }] = useLazyQuery(
    GET_ADDRESS_BY_NICKNAME,
  );
  const {
    data: nicknameByAddress,
    loading: isLoadingNicknameByAddress,
    refetch: refetchNicknameByAddress,
  } = useQuery(GET_NICKNAME_BY_ADDRESS, {
    variables: { getNicknameByAddressAddress2: connectedAccount },
    skip: !connectedAccount,
  });
  const { data: referrals, loading: isLoadingReferrals } = useQuery(
    GET_REFERRALS_BY_ADDRESS,
    {
      variables: { address: connectedAccount },
      skip: !connectedAccount,
    },
  );

  const associateReferral = async () => {
    try {
      const addressByNicknameResponse = await _getAddressByNickname({
        variables: {
          getAddressByNicknameNickname2: localStorage.getItem("ref"),
        },
      });
      const referrerAddress =
        addressByNicknameResponse?.data?.getAddressByNickname;
      if (!!referrerAddress) {
        await _associateReferral({
          variables: {
            referred: connectedAccount,
            referrer: referrerAddress,
          },
        });
        localStorage.removeItem("ref");
        return true;
      } else {
        notify(
          `The nickname ${localStorage.getItem(`ref`)} is not valid`,
          "error",
        );
        localStorage.removeItem("ref");
      }
    } catch (err) {
      notify("An error ocurred while associating the referrer", "error");
    }
  };

  const createNickname = async (nickname) => {
    try {
      const response = await _createNickname({
        variables: {
          nickname: `${nickname}`,
          address: connectedAccount,
        },
      });
      if (!!response.data?.createNickname?.success) {
        notify("Referral nickname created successfully!", "success");
        return true;
      } else {
        notify(response.data?.createNickname?.message, "error");
      }
    } catch (err:any) {
      notify(err?.message || err?.msg || err, "error");
    }
    return false;
  };

  useEffect(() => {
    if (typeof window !== `undefined`) {
      const urlParams = new URLSearchParams(window.location.search);
      const ref = urlParams.get("ref") || urlParams.get("via");
      if (ref) localStorage.setItem(`ref`, ref);
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem(`ref`) && !!connectedAccount) {
      associateReferral();
    }
  }, [connectedAccount]);

  return {
    createNickname,
    isCreatingNickname,
    nickname: nicknameByAddress?.getNicknameByAddress || undefined,
    referralLink: `${PUBLIC_URL}?ref=${nicknameByAddress?.getNicknameByAddress || ``}`,
    isLoadingNickname,
    refetchNicknameByAddress,
    referrals:
      referrals !== undefined ? referrals?.getReferralsByAddress : referrals,
    isLoadingReferrals,
  };
}
