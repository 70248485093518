import React, { useContext } from "react";
import useReferrals from "../hooks/useReferrals";

const ReferralsContext = React.createContext({
  referrals: undefined as any[] | undefined,
});

const ReferralsProvider = ({ children }) => {
  const {
    referrals,
  } = useReferrals();

  return (
    <ReferralsContext.Provider
      value={{
        referrals
      }}
    >
      {children}
    </ReferralsContext.Provider>
  );
};

export { ReferralsContext, ReferralsProvider }; 