import React, { FC, useEffect, useState } from "react";
import Toastr from "../components/shared/Toastr";
import useNFTs from "../hooks/useNFTs";
import { NFTS_CHAIN_ID } from "../config/constants";
import { contractAddresses } from "../config/contractAddressList";

const NFTContext = React.createContext({
  nfts: [],
  nft: null,
});

const NFTProvider = ({ children }) => {

  const { nfts } = useNFTs(contractAddresses[NFTS_CHAIN_ID].OLYMPEX_PAS_ADDRESS);
  const nft: any = nfts?.length > 0 ? nfts[0] : null;
  // TODO: add main Olympex Pass NFTs selection logic

  return (
    <NFTContext.Provider
      value={{
        nfts,
        nft,
      }}
    >
      {children}
    </NFTContext.Provider>
  );
};

export { NFTContext, NFTProvider };
