import { useContext, useEffect, useState } from "react";
import {
  NFTS_CHAIN_ID,
  OLYMPEX_PASS_BASE_TOKEN_URI,
} from "../config/constants";
import { ethers } from "ethers";
import { ChainContext } from "../contexts/ChainContext";
import BigNumber from "bignumber.js";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  GENERATE_METADATA_NFTS,
  GET_NFT_SIGNATURE,
  GET_SAVED_FEES_BY_ADDRESS,
  GET_TOKEN_IDS_BY_ADDRESS,
} from "../config/queries";
import useContract from "./useContract";
import { ToastrContext } from "../contexts/ToastrContext";
import { OLYMPEX_PAS_ABI } from "../abis/OLYMPEX_PAS_ABI";
import useChainCalls from "./useChainCalls";

export default function useNFTs(nftAddress) {
  const { connectedAccount } = useContext(ChainContext);
  const [getNFTSignature, { loading, error, data: nftData }] = useLazyQuery(
    GET_NFT_SIGNATURE,
    { fetchPolicy: "network-only" },
  );
  const { data: _tokenIds } = useQuery(GET_TOKEN_IDS_BY_ADDRESS, {
    variables: { address: connectedAccount },
    skip: !connectedAccount
  });
  const { data: _savedFees } = useQuery(GET_SAVED_FEES_BY_ADDRESS, {
    variables: { address: connectedAccount },
    skip: !connectedAccount
  });
  const [generateMetadataNFT] = useMutation(GENERATE_METADATA_NFTS);
  const [loadingMint, setLoadingMint] = useState(false);
  const [loadingNfts, setLoadingNfts] = useState(false);
  const [nfts, setNfts] = useState<any>();
  const { notify } = useContext(ToastrContext);
  const iface = new ethers.Interface(OLYMPEX_PAS_ABI);

  const contract = useContract(nftAddress, OLYMPEX_PAS_ABI);
  const nft = nfts?.length
    ? nfts.reduce((prev, current) =>
        prev &&
        ethers.formatEther(
          `${prev?.attributes?.find((e) => e?.trait_type === "Points")?.value}`,
        ) >
          ethers.formatEther(
            `${current?.attributes?.find((e) => e?.trait_type === "Points")?.value}`,
          )
          ? prev
          : current,
      )
    : undefined;
  const tokenIds =
    _tokenIds?.getTokenIdsByAddress !== undefined
      ? _tokenIds?.getTokenIdsByAddress
      : _tokenIds;
  const savedFees =
    _savedFees?.getSavedFeesByAddress !== undefined
      ? _savedFees?.getSavedFeesByAddress
      : _savedFees;

  const mint = async () => {
    setLoadingMint(true);
    let result = false;
    try {
      const data = await getNFTSignature({
        variables: {
          account: connectedAccount,
        },
      });
      const signature = data.data.mintSignature;
      if (signature && signature.length === 132 && contract) {
        try {
          const tx = await contract.mint(signature);
          const txWait = await tx?.wait(1);
          const mintedEvent = txWait.logs.find(
            (log) => {
              try {
                const parsedLog = contract.interface.parseLog({
                  topics: log.topics,
                  data: log.data,
                });
                return parsedLog?.name === "Minted";
              } catch {
                return false;
              }
            }
          );
          const tokenId = mintedEvent ? 
            contract.interface.parseLog({
              topics: mintedEvent.topics,
              data: mintedEvent.data,
            })?.args?.tokenId_?.toString() : undefined;
          const response = await generateMetadataNFT({
            variables: {
              account: connectedAccount,
              tokenId: parseInt(tokenId),
            },
          });
          try {
            result = JSON.parse(
              response?.data?.generateMetadataNFT?.metadataURL,
            );
          } catch (_) {}
        } catch (err:any) {
          notify(err?.message || err, "error");
        }
      } else {
        notify(signature, "error");
      }
    } catch (_) {}
    setLoadingMint(false);
    return result;
  };

  const getNftsMetadata = async () => {
    setLoadingNfts(true);
    if (tokenIds && tokenIds?.length) {
      const responses = await Promise.allSettled(
        tokenIds.map((_tokenId) =>
          fetch(`${OLYMPEX_PASS_BASE_TOKEN_URI}/${_tokenId}.json`),
        ),
      );
      const jsonResponses = await Promise.all(
        responses
          .filter((e: any) => e?.value?.status === 200)
          .map((e: any) => e?.value?.json()),
      );
      setNfts(jsonResponses);
    } else {
      setNfts([]);
    }
    setLoadingNfts(false);
  };

  useEffect(() => {
    getNftsMetadata(); 
  }, [tokenIds]);

  const calls = [
    {
      address: nftAddress,
      chainId: NFTS_CHAIN_ID,
      calldata: iface.encodeFunctionData("MAX_SUPPLY"),
    },
    {
      address: nftAddress,
      chainId: NFTS_CHAIN_ID,
      calldata: iface.encodeFunctionData("MAX_WL_TOKENS"),
    },
    {
      address: nftAddress,
      chainId: NFTS_CHAIN_ID,
      calldata: iface.encodeFunctionData("currentCount"),
    },
  ];

  const response = useChainCalls(calls);
  
  let objResponse = {
    maxSupply: undefined as any,
    maxSupplyPerWallet: undefined as any,
    costPerToken: undefined as any,
    totalSupply: undefined as any,
    loading: true,
  };
  if (response) {
    objResponse.maxSupply =
      response?.[0]?.value !== undefined
        ? new BigNumber(response[0].value).toNumber()
        : undefined;
    objResponse.maxSupplyPerWallet =
      response?.[1]?.value !== undefined
        ? new BigNumber(response[1].value).toNumber()
        : undefined;
    objResponse.costPerToken = 0;
    objResponse.totalSupply =
      response?.[2]?.value !== undefined
        ? new BigNumber(response[2].value).toNumber()
        : undefined;
    objResponse.loading = response?.[0]?.value === undefined;
  }

  return {
    mint,
    loadingMint,
    nfts,
    nft,
    loadingNfts,
    getNftsMetadata,
    savedFees,
    ...objResponse,
  };
}
