import { useContext, useState } from "react";
import { ChainConfigContext } from "../contexts/ChainConfigContext";
import {
  TEST_FORKED_CHAIN_ID,
  TEST_CHAIN_ID,
} from "../config/constants";
import useCoingecko from "./useCoingecko";
import { isNativeToken } from "../utils/tokenUtils";

interface PriceCache {
  price: number;
  timestamp: number;
}

const CACHE_KEY_PREFIX = 'token_price_cache_';
const CACHE_DURATION = 120 * 1000;

export interface IGetTokenPrices {
  ratio: number;
  tokenOne: number;
  tokenTwo: number;
  dateTime: Date;
}

export default function useTokenPrices() {
  const { getChainById } = useContext(ChainConfigContext);
  const { getPriceByTokenAddress } = useCoingecko();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>();
  const [error, setError] = useState<any>();

  const getCacheKey = (chainId: number, tokenAddress: string): string => {
    return `${chainId}-${tokenAddress.toLowerCase()}`;
  };

  const getCachedPrice = (chainId: number, tokenAddress: string): number | null => {
    const key = CACHE_KEY_PREFIX + getCacheKey(chainId, tokenAddress);
    const cached = localStorage.getItem(key);
    
    if (cached) {
      const parsedCache: PriceCache = JSON.parse(cached);
      if (Date.now() - parsedCache.timestamp < CACHE_DURATION) {
        return parsedCache.price;
      }
      // Remove expired cache
      localStorage.removeItem(key);
    }
    return null;
  };

  const setCachedPrice = (chainId: number, tokenAddress: string, price: number) => {
    const key = CACHE_KEY_PREFIX + getCacheKey(chainId, tokenAddress);
    const cacheData: PriceCache = {
      price,
      timestamp: Date.now()
    };
    localStorage.setItem(key, JSON.stringify(cacheData));
  };

  const getTokenPrices = async (
    chainIdFrom: number,
    tokenAddressFrom: string | undefined,
    chainIdTo?: number | undefined,
    tokenAddressTo?: string | undefined,
  ): Promise<IGetTokenPrices> => {
    setLoading(true);

    if (chainIdFrom === TEST_CHAIN_ID) chainIdFrom = TEST_FORKED_CHAIN_ID;
    if (chainIdTo === TEST_CHAIN_ID) chainIdTo = TEST_FORKED_CHAIN_ID;

    const chainFrom = getChainById(chainIdFrom);
    const chainTo = getChainById(chainIdTo as any);

    if (isNativeToken(tokenAddressFrom)) tokenAddressFrom = chainFrom?.weth;
    if (isNativeToken(tokenAddressTo)) tokenAddressTo = chainTo?.weth;

    let promises: Promise<number | null>[] = [];
    
    if (tokenAddressFrom) {
      const cachedPrice = getCachedPrice(chainIdFrom, tokenAddressFrom);
      promises.push(
        cachedPrice !== null
          ? Promise.resolve(cachedPrice)
          : getPriceByTokenAddress(chainIdFrom, tokenAddressFrom)
      );
    }
    
    if (tokenAddressTo && chainIdTo) {
      const cachedPrice = getCachedPrice(chainIdTo, tokenAddressTo);
      promises.push(
        cachedPrice !== null
          ? Promise.resolve(cachedPrice)
          : getPriceByTokenAddress(chainIdTo, tokenAddressTo)
      );
    }

    const tokenPricesResults = await Promise.allSettled(promises);
    const tokenPrices = tokenPricesResults.map((result, index) => {
      if (result.status === 'fulfilled' && result.value !== null) {
        // Cache the new prices
        if (index === 0 && tokenAddressFrom) {
          setCachedPrice(chainIdFrom, tokenAddressFrom, result.value);
        } else if (index === 1 && tokenAddressTo && chainIdTo) {
          setCachedPrice(chainIdTo, tokenAddressTo, result.value);
        }
        return result.value;
      }
      return 0;
    });

    const data: IGetTokenPrices = {
      ratio:
        parseFloat(tokenPrices[0].toString()) /
        (tokenPrices.length > 1 ? tokenPrices[1] : 1),
      tokenOne: parseFloat(tokenPrices[0].toString()),
      tokenTwo: tokenPrices.length > 1 ? tokenPrices[1] : 0,
      dateTime: new Date(),
    };

    setData({ tokenPrices: data });
    setLoading(false);

    return data;
  };

  return { getTokenPrices, loading, error, data };
}
